.about-us-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: whitesmoke;
}

.about-us-text {
  font-size: 24px;
  padding: 20px;
  background-color: whitesmoke;
}
