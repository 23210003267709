.list-item-container {
  display: flex;
  flex: 1;
}

.ant-spin-container {
  padding: 0 2px;
}

.list-item-container:hover {
  cursor: pointer;
}

.list-item-info {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  margin-right: 10px;
  width: 100%;
}

/* logos are located here: logomakr.com/app/10U4X8 */
.yellow {
  background-color: #feee7b;
}

.green {
  background-color: #b4ffb4;
}

.grey {
  background-color: #dcdcdb;
}

.ant-list-item {
  border-radius: 10px;
  /* transition: all 1s ease-out; */
}

.selected-container.yellow {
  border: 2px solid #c2a90f !important;
}

.selected-container.green {
  border: 2px solid #00b400 !important;
}

.selected-container.grey {
  border: 2px solid #888888 !important;
}

.ant-list-item {
  border-bottom: none;
  margin-bottom: 10px;
}

.menu-container {
  float: right;
  margin: 7px 0;
}

.list-item-header {
  font-size: 16px;
  font-weight: 500;
  display: flex;
  flex-direction: column;
}

.happy-hour-text {
  font-weight: bold;
}
