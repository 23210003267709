.side-list-container {
  overflow-y: scroll;
  /* height: 90%; */
  margin-left: 20px;
  padding: 10px 10px;
  width: 25%;
  max-width: 350px;
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px,
    rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px,
    rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
  z-index: 1;
  background-color: white;
}

/* Below is needed for hiding the scrollbar */
* {
  -ms-overflow-style: none;
}
::-webkit-scrollbar {
  display: none;
}

.ant-picker-dropdown{
  /* z-index: 100; */
}

.ant-list-items {
  padding: 0px !important;
}

.infinite-scroll-component {
  margin-top: 73px;
}

.filter-container {
  position: fixed;
  width: calc(25% - 20px);
  left: 20px;
  margin-left: 10px;
  max-width: 330px;
}

.ant-picker-content {
  max-height: 200px;
}

@media (max-width: 600px) {
  .infinite-scroll-component {
    margin-top: 0px;
    z-index: 1;
  }

  .side-list-container {
    width: 100%;
    margin: 0 !important;
    max-width: 600px;
    /* max-height: 35% !important; */
    border-radius: 0px;
    transition: max-height 0.5s;
    z-index: 0;
  }

  .filter-container {
    position: sticky !important;
    width: 100%;
    margin-left: 0;
    left: 0px;
    max-width: 600px;
    border-radius: 0px;
  }

  .ant-picker-time-panel-column {
    width: 80px !important;
  }

  .ant-picker-time-panel-cell-inner {
    line-height: 35px !important;
    height: 35px !important;
  }

  .mobile-list-header{
    display: flex;
    align-items: center;
    background-color: white;
    padding: 3px 0px;

    .ant-btn{
      margin: 0px 10px;
    }

    .ant-btn-icon-only{
      border-radius: 30%;
    }

    .filter-container{
      flex: 1;
      padding: 3px 10px;
      border-radius: 10px;
    }
  }
}
