.header-container {
  width: 100%;
  top: 0;
  margin-bottom: 5px;
}

.header-logo {
  margin-left: 20px;
  float: left;
  height: 80px;
  width: 85px;
  margin: 0px 5px 4px 35px;
  position: sticky;
  top: 15%;
  transform: translateY(-15%);
}

.header {
  padding: 0 !important;
  width: 100%;
  background: white !important;
}
