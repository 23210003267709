.home-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
}

.map {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.marker-label {
  margin-right: 129px;
  font-family: Arial, Helvetica, sans-serif;
  width: 100px;
  /* white-space: nowrap; Prevents wrapping to the next line */
  overflow: hidden; /* Hides the content that overflows */
  text-overflow: ellipsis; /* Displays an ellipsis (...) for overflowed content */
  max-width: 100px;
  overflow-wrap: break-word;
  /* background-color: white; */
  /* right: 100px; */
}

.selected {
  overflow: visible;
  text-overflow: unset;
  font-weight: bold;
  max-width: 80px;
  width: 80px;
  white-space: normal;
  overflow-wrap: break-word;
  backdrop-filter: blur(10px);
  position: "relative";
  z-index: 1000;
}

@media (max-width: 600px) {
  .home-container {
    justify-content: center;
  }

  .ant-layout {
    /* height: 50% !important; */
  }
}
